import React from 'react';
import * as Yup from 'yup';
import {observer} from 'mobx-react-lite';
import Grid from '@material-ui/core/Grid';
import Form from './Form';
import TextField from './fields/TextField';

const schema = Yup.object().shape({
  login: Yup.string().email().required(),
  password: Yup.string().required()
});

const initialValues = {
  login: '',
  password: ''
};

const LoginForm = ({onSubmit}) => {
  return (
    <Form onSubmit={onSubmit} schema={schema} values={initialValues} submitLabel='Login'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth name='login' label='Email' type='email' />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth name='password' label='Password' type='password' />
        </Grid>
      </Grid>
    </Form>
  );
};

export default observer(LoginForm);
