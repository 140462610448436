import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import store from 'store';
import withRoot from './../withRoot';
import PrivateRoute from './PrivateRoute';
import Notifications from './Notifications';
import Uploader from './Uploader';
import createHTTPAgent from './../agents/HTTPAgent';

import NotFoundPage from './pages/NotFoundPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import SettingsEditPage from './pages/Settings/Edit';
import UsersIndexPage from './pages/User/Index';
import UserEditPage from './pages/User/Edit';
import ModuleCategoriesIndexPage from './pages/ModuleCategory/Index';
import ModuleCategoryEditPage from './pages/ModuleCategory/Edit';
import ModulesIndexPage from './pages/Module/Index';
import ModuleEditPage from './pages/Module/Edit';
import ModuleStepsIndexPage from './pages/ModuleStep/Index';
import ModuleStepEditPage from './pages/ModuleStep/Edit';
import ModuleComponentsIndexPage from './pages/ModuleComponent/Index';
import ModuleComponentEditPage from './pages/ModuleComponent/Edit';
import GymCategoriesIndexPage from './pages/GymCategory/Index';
import GymCategoryEditPage from './pages/GymCategory/Edit';
import GymItemsIndexPage from './pages/GymItem/Index';
import GymItemEditPage from './pages/GymItem/Edit';

import createRootStore from './../stores/RootStore';
import RootStoreContext from './../contexts/RootStoreContext';

const httpAgent = createHTTPAgent(process.env.REACT_APP_API_URL);

const rootStore = createRootStore(httpAgent, store);

const App = () => (
  <RootStoreContext.Provider value={rootStore}>
    <Notifications />
    <Uploader />
    <BrowserRouter>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute path='/' component={ProtectedArea} />
      </Switch>
    </BrowserRouter>
  </RootStoreContext.Provider>
);

const ProtectedArea = () => (
  <Switch>
    <Route exact path='/' component={MainPage} />
    <Route exact path='/settings' component={SettingsEditPage} />
    <Route exact path='/settings/:category' component={SettingsEditPage} />
    <Route exact path='/users' component={UsersIndexPage} />
    <Route exact path='/users/:userId' component={UserEditPage} />
    <Route exact path='/module_categories' component={ModuleCategoriesIndexPage} />
    <Route exact path='/module_categories/:moduleCategoryId' component={ModuleCategoryEditPage} />
    <Route exact path='/modules' component={ModulesIndexPage} />
    <Route exact path='/modules/:moduleId' component={ModuleEditPage} />
    <Route exact path='/modules/:moduleId/steps' component={ModuleStepsIndexPage} />
    <Route exact path='/modules/:moduleId/steps/:moduleStepId' component={ModuleStepEditPage} />
    <Route exact path='/modules/:moduleId/steps/:moduleStepId/components'
      component={ModuleComponentsIndexPage} />
    <Route exact path='/modules/:moduleId/steps/:moduleStepId/components/:moduleComponentId'
      component={ModuleComponentEditPage} />
    <Route exact path='/gym_categories' component={GymCategoriesIndexPage} />
    <Route exact path='/gym_categories/:gymCategoryId' component={GymCategoryEditPage} />
    <Route exact path='/gym_categories/:gymCategoryId/items' component={GymItemsIndexPage} />
    <Route exact path='/gym_categories/:gymCategoryId/items/:gymItemId'
      component={GymItemEditPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

export default withRoot(App);
