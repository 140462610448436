import React from 'react';
import * as Yup from 'yup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import roles from './../../enum/roles';
import Form from './Form';
import TextField from './fields/TextField';
import SelectField from './fields/SelectField';

const schema = Yup.object().shape({
  login: Yup.string().email().required(),
  password: Yup.string().min(5),
  role: Yup.string().required()
});

const initialValues = {
  login: '',
  name: '',
  role: null
};

const output = (values) => ({
  ...values,
  password: values.password || undefined
});

const UserForm = ({onSubmit, onDeleteProgress, onCreateNotification, values}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Form {...{onSubmit, schema, output}} values={values || initialValues} patch={!!values}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth name='login' label='Login' />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth name='password' label='Password' />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth name='name' label='Name' />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectField name='role' label='Role'
                    options={Object.keys(roles).map((role) => ({
                      value: roles[role],
                      label: role
                    }))}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      </Grid>
      <Grid item xs={12} >
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
            <Typography>Development / Debug</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              {onDeleteProgress
                ? <Grid item xs={12}>
                  <Button variant='contained' color='secondary' onClick={onDeleteProgress}>
                    Delete user progress
                  </Button>
                </Grid>
                : null }
              {onCreateNotification
                ? <Grid item xs={12}>
                  <Form onSubmit={onCreateNotification} submitLabel='Send notification'
                    values={{}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <TextField fullWidth name='notification.title' label='Title' />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <TextField fullWidth name='notification.body' label='Body' />
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
                : null }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
};

export default UserForm;
