module.exports = {
  AUDIO: 'audio',
  VIDEO: 'video',
  SABOTEURS_AUDIO: 'saboteurs_audio',
  MULTI_SELECT_CARDS: 'multi_select_cards',
  CHECKIN: 'checkin',
  MULTI_CHECKIN: 'multi_checkin',
  ROUTINE_SELECTOR: 'routine_selector',
  GYM_PRACTICE: 'gym_practice',
  PQ_ASSESSMENT: 'pq_assessment'
};
