import React from 'react';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from './../fields/TextField';
import Form from './../Form';
import InstructionsFieldset, {output as instructionsOutput, schema as instructionsSchema}
  from './../fieldsets/InstructionsFieldset';

const schema = Yup.object().shape({
  onboarding: Yup.object().shape({
    instructions: instructionsSchema
  })
});

const initialValues = {
};

const output = (value) => ({
  ...value,
  onboarding: {
    ...value.onboarding,
    instructions: instructionsOutput(value.onboarding.instructions)
  }
});

const OnboardingSettingsForm = ({onSubmit, values}) => {
  return (
    <Form {...{onSubmit, schema, output}} values={values || initialValues} patch={!!values}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Instructions' />
            <CardContent>
              <InstructionsFieldset name='onboarding.instructions' />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Video' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth name='onboarding.video.vimeo_id' label='Vimeo Id' />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default OnboardingSettingsForm;
