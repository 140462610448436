import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useLocation} from './../../../hooks/router';

const UsersIndexPage = () => {
  const {usersStore} = useContext(RootStoreContext);
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = usersStore.getCollection();
    collection.load();
    return collection;
  }, [usersStore]);

  const columns = useMemo(
    () => ([{
      title: 'Login',
      field: 'login'
    }, {
      title: 'Name',
      field: 'name'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<AddIcon />),
      tooltip: 'Add User',
      isFreeAction: true,
      onClick: () => location.navigate(`/users/new`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit User',
      onClick: (e, user) => location.navigate(`/users/${user._id}`)
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete User',
      onClick: async (e, user) => {
        if (window.confirm(`Are you sure you want to delete user "${user.login}"?`)) {
          await usersStore.delete(user._id);
          await collection.load();
        }
      }
    }]),
    [collection, location, usersStore]
  );

  return (
    <Page title='Users'>
      <EntityList title='Users' columns={columns} actions={actions} collection={collection} />
    </Page>
  );
};

export default observer(UsersIndexPage);
