import React, {useMemo} from 'react';
import * as Yup from 'yup';
import ObjectID from 'bson-objectid';
import capitalize from 'lodash/capitalize';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Form from './Form';
import HideableField from './fields/HideableField';
import TextField from './fields/TextField';
import SwitchField from './fields/SwitchField';
import SelectField from './fields/SelectField';
import AudioFieldset from './fieldsets/AudioFieldset';
import InstructionsFieldset, {output as instructionsOutput, schema as instructionsSchema}
  from './fieldsets/InstructionsFieldset';
import ComponentDataFieldset, {output as componentDataOutput, schema as componentDataSchema}
  from './fieldsets/ComponentDataFieldset';
import {emptyStringsToNull} from './utils';
import scoreTypes from './../../enum/scoreTypes';

const schema = Yup.object().shape({
  title: Yup.string().required(),
  subtitle: Yup.string().required(),
  description: Yup.string().required(),
  icon: Yup.string().required(),
  color: Yup.string().required(),
  instructions: instructionsSchema,
  data_sets: componentDataSchema
});

const initialValues = {
  title: '',
  subtitle: '',
  description: '',
  icon: '',
  color: '',
  has_shortcut: false
};

const output = (value) => ({
  ...value,
  instructions: instructionsOutput(value.instructions),
  shortcut: emptyStringsToNull(value.shortcut),
  data_sets: componentDataOutput(value.data_sets)
});

const ModuleComponentForm = ({onSubmit, values}) => {
  const moduleComponent = useMemo(
    () => values || {_id: new ObjectID() + '', ...initialValues}, [values]
  );

  return (
    <Form {...{onSubmit, schema, output}} values={moduleComponent} patch={!!values}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='title' label='Title' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='subtitle' label='Subtitle' />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectField name={`icon`} label='Icon'
                    options={['insight', 'preparation', 'practice'].map((icon) => (
                      {value: icon, label: capitalize(icon.replace(/_/g, ' '))}
                    ))}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectField name={`color`} label='Color'
                    options={[
                      {value: 'D6F2FF', label: 'Insight'},
                      {value: 'ECFFD6', label: 'Preparation'},
                      {value: 'DDFAE8', label: 'Practice'}
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                    name='description' label='Description'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Instructions' />
            <CardContent>
              <InstructionsFieldset name='instructions' />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Shortcut' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SwitchField name='has_shortcut' label='With Shortcut' />
                </Grid>
                <HideableField ifName='has_shortcut'>
                  <Grid item xs={12}>
                    <TextField fullWidth name='shortcut.available_after_s'
                      label='Available After' type='number' helper='Seconds'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth name='shortcut.title'
                      label='Title'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                      name='shortcut.description' label='Description'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth name='shortcut.message.title' label='Message Title' />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                      name='shortcut.message.body' label='Message Body'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AudioFieldset name='shortcut.data'
                      targetFile={`component-${moduleComponent._id}-shortcut`}
                    />
                  </Grid>
                </HideableField>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Points for Completion' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SwitchField name='has_completion_points' label='With Points for Completion' />
                </Grid>
                <HideableField ifName='has_completion_points'>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                      name='completion_points.message' label='Message'
                    />
                  </Grid>
                  {Object.values(scoreTypes).map((scoreType) => (
                    <Grid item xs={12} key={scoreType}>
                      <TextField fullWidth name={`completion_points.${scoreType}`}
                        label={capitalize(scoreType.replace(/_/g, ' '))} type='number'
                      />
                    </Grid>
                  ))}
                </HideableField>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Points per Value' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SwitchField name='has_per_value_points' label='With Points per Value' />
                </Grid>
                <HideableField ifName='has_per_value_points'>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                      name='per_value_points.message' label='Message'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                      name='per_value_points.completed_message' label='Message when completed'
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextField fullWidth name={`per_value_points.max_value`}
                      label='Max Value' type='number'
                      helper='Points are not given if the value is above the max value'
                    />
                  </Grid>
                  {Object.values(scoreTypes).map((scoreType) => (
                    <Grid item xs={12} key={scoreType}>
                      <TextField fullWidth name={`per_value_points.${scoreType}`}
                        label={capitalize(scoreType.replace(/_/g, ' '))} type='number'
                      />
                    </Grid>
                  ))}
                </HideableField>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ComponentDataFieldset name='data_sets' />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ModuleComponentForm;
