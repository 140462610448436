import React, {useMemo, useCallback, useState, useEffect} from 'react';
import {useField} from 'formik';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import {observer} from 'mobx-react-lite';
import Select, {createFilter} from 'react-select';
import {useTheme} from '@material-ui/core/styles';
import useStyles from './styles';
import components from './components';

const SelectField = ({
  name, label, options, collection, isMulti, loadOnMount, labelField = 'label',
  valueField = 'value', ...props
}) => {
  const [{value, onChange}, meta] = useField({name});
  const [optionsCache, setOptionsCache] = useState(options || []);
  const classes = useStyles();
  const theme = useTheme();
  const items = collection ? collection.items : {};

  useEffect(() => {
    loadOnMount && collection && collection.load();
  }, [collection, loadOnMount]);

  // cache previous options so selected options will be available
  // even if the filtered response from the server doesn't contain them
  useEffect(
    () => {
      if (items.state === 'fulfilled') {
        setOptionsCache((oldCache) => {
          const newCache = [
            ...oldCache,
            ...get(items, 'value.data', [])
          ];

          return uniqBy(newCache, '_id');
        });
      }
    },
    [items, items.state]
  );

  const selectStyles = useMemo(() => ({
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    }),
    indicatorsContainer: (base) => ({
      ...base,
      '& > div': {
        paddingTop: 4
      }
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1400
    })
  }), [theme.palette.text.primary]);

  const textFieldProps = useMemo(() => ({
    label: label,
    error: !!meta.error,
    helperText: meta.error || '',
    InputLabelProps: {
      htmlFor: name,
      shrink: true
    }
  }), [label, meta.error, name]);

  const handleChange = useCallback(
    (val) => {
      let value;

      if (isMulti) {
        value = val ? val.map((v) => v[valueField]) : [];
      } else {
        value = val ? val[valueField] : null;
      }

      return onChange({target: {name, value}});
    },
    [isMulti, name, onChange, valueField]
  );

  const handleInputChange = useCallback(
    (query) => {
      if (!collection) {
        return;
      }

      const onQueryDebounced = debounce(() => {
        collection.setQuery(query);
      }, 500);

      onQueryDebounced(query);
    },
    [collection]
  );

  let selectedOption = isMulti ? [] : null;

  if (value) {
    selectedOption = isMulti
      ? value.map((v) => optionsCache.find((option) => option[valueField] === v))
        .filter((v) => v)
      : optionsCache.find((option) => option[valueField] === value);
  }

  const getOptionLabel = useCallback((option) => option[labelField], [labelField]);
  const getOptionValue = useCallback((option) => option[valueField], [valueField]);
  const filterOption = useCallback(
    createFilter({ignoreCase: true, ignoreAccents: true, trim: true, matchFromStart: false}),
    []
  );

  return (
    <Select
      {...props}
      options={optionsCache}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      filterOption={filterOption}
      value={selectedOption}
      classes={classes}
      styles={selectStyles}
      inputId={name}
      TextFieldProps={textFieldProps}
      menuPortalTarget={document.querySelector('body')}
      components={components}
      onChange={handleChange}
      onInputChange={handleInputChange}
      isLoading={items.state === 'pending'}
      isMulti={isMulti}
    />
  );
};

export default observer(SelectField);
