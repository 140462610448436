import {decorate, observable, action} from 'mobx';
import {fromPromise} from 'mobx-utils';

export const createCRUDStore = (httpAgent, commonStore, name, interceptors = {}) => {
  return decorate({
    itemToEdit: fromPromise.resolve(null),
    createdItem: fromPromise.resolve(null),
    updatedItem: fromPromise.resolve(null),

    getCollection (filter = {}) {
      return decorate({
        filter,
        query: '',
        page: 0,
        perPage: 20,
        items: fromPromise.resolve(null),

        setFilter (filter) {
          this.filter = filter;
          this.load();
        },

        setQuery (query) {
          if (this.query === query) {
            return;
          }

          this.query = query;
          this.load();
        },

        setPage (page) {
          if (this.page === page) {
            return;
          }

          this.page = page;
          this.load();
        },

        setPerPage (perPage) {
          this.perPage = perPage;
          this.page = 0;
          this.load();
        },

        load () {
          this.items = fromPromise((async () => {
            const {page, perPage, query, filter} = this;

            const {data, total} = await httpAgent.getAll({page, perPage, query, filter});

            return {data, total, page, perPage, query};
          })(), this.items);

          return this.items;
        }
      }, {
        items: observable,
        load: action
      });
    },

    loadToEdit (id) {
      this.itemToEdit = fromPromise(httpAgent.getById(id));

      return this.itemToEdit;
    },

    async create (data) {
      let createData = data;

      if (interceptors.beforeCreate) {
        createData = await interceptors.beforeCreate(data);
      }

      this.createdItem = fromPromise(httpAgent.create(createData));

      await this.createdItem;

      commonStore.addNotification(`${name} successfully created`, 'success');
    },

    async update (id, data) {
      let updateData = data;

      if (interceptors.beforeUpdate) {
        updateData = await interceptors.beforeUpdate(data);
      }

      this.updatedItem = fromPromise(httpAgent.updateById(id, updateData));

      await this.updatedItem;

      commonStore.addNotification(`${name} successfully updated`, 'success');
    },

    async delete (id) {
      await httpAgent.deleteById(id);

      commonStore.addNotification(`${name} successfully deleted`, 'success');
    }
  }, {
    itemToEdit: observable,
    createdItem: observable,
    updatedItem: observable,
    loadToEdit: action,
    create: action,
    update: action,
    delete: action
  });
};

export const uploadAudio = async (audio, filesStore) => {
  const {file} = audio;

  const duration = await getAudioDuration(file.url);

  const link = await filesStore.upload(file);

  return {
    link,
    file_name: file.name,
    duration_s: Math.round(duration)
  };
};

export const getAudioDuration = async (file) => {
  return new Promise((resolve) => {
    const audio = new Audio();
    audio.addEventListener('loadedmetadata', () => {
      resolve(audio.duration);
    });
    audio.src = file;
  });
};
