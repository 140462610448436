import React, {useContext, useCallback} from 'react';
import classnames from 'classnames';
import {observer} from 'mobx-react-lite';
import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {amber, green} from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import RootStoreContext from './../contexts/RootStoreContext';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-wrap'
  }
}));

const Notification = ({id, text, variant, onClose}) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  const handleClose = useCallback(() => onClose(id), [id, onClose]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classnames(classes[variant])}
        aria-describedby={id}
        message={
          <span id={id} className={classes.message}>
            <Icon className={classnames(classes.icon, classes.iconVariant)} />
            {text}
          </span>
        }
      />
    </Snackbar>
  );
};

const Notifications = () => {
  const {commonStore} = useContext(RootStoreContext);

  const {notifications} = commonStore;

  const handleClose = useCallback((id) => commonStore.removeNotification(id), [commonStore]);

  return (
    <div>
      {notifications.map(({id, text, variant}) => (
        <Notification key={id} {...{id, text, variant}} onClose={handleClose} />
      ))}
    </div>
  );
};

export default observer(Notifications);
