import React, {useContext, useLayoutEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import LoadingOverlay from './../../LoadingOverlay';
import UserForm from './../../forms/UserForm';
import {useParams, useLocation} from './../../../hooks/router';

const UserEditPage = () => {
  const {usersStore} = useContext(RootStoreContext);
  const params = useParams();
  const location = useLocation();

  const isNew = params.userId === 'new';

  useLayoutEffect(
    () => void (isNew || usersStore.loadToEdit(params.userId)),
    [usersStore, params, isNew]
  );

  const handleSubmit = useCallback(async (data) => {
    if (isNew) {
      await usersStore.create(data);
    } else {
      await usersStore.update(params.userId, data);
    }

    location.navigate('/users');
  }, [isNew, location, params.userId, usersStore]);

  const handleDeleteProgress = useCallback(async () => {
    await usersStore.deleteProgress(params.userId);
  }, [params.userId, usersStore]);

  const handleCreateNotification = useCallback(async (data) => {
    await usersStore.createNotification(params.userId, data);
  }, [params.userId, usersStore]);

  const {itemToEdit, createdItem, updatedItem} = usersStore;
  const pending = itemToEdit.state === 'pending' ||
  createdItem.state === 'pending' ||
  updatedItem.state === 'pending';

  const renderForm = () => {
    if (isNew) {
      return (
        <UserForm onSubmit={handleSubmit} />
      );
    }

    return itemToEdit.case({
      fulfilled: (value) => value
        ? <UserForm values={value} onSubmit={handleSubmit}
          onDeleteProgress={handleDeleteProgress}
          onCreateNotification={
            (value.fcm_reg_tokens && value.fcm_reg_tokens.length) ? handleCreateNotification : null
          }
        />
        : null,
      rejected: (error) => (<div>An error occurred: {error + ''}</div>)
    });
  };

  return (
    <Page title={isNew ? 'Add User' : 'Edit User'}>
      {pending ? <LoadingOverlay size={42} /> : null}
      {renderForm()}
    </Page>
  );
};

export default observer(UserEditPage);
