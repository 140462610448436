import React, {useContext, useLayoutEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import LoadingOverlay from './../../LoadingOverlay';
import ModuleComponentForm from './../../forms/ModuleComponentForm';
import {useParams, useLocation} from './../../../hooks/router';

const ModuleComponentEditPage = () => {
  const {moduleComponentsStore} = useContext(RootStoreContext);
  const {moduleId, moduleStepId, moduleComponentId} = useParams();
  const location = useLocation();

  const isNew = moduleComponentId === 'new';

  useLayoutEffect(
    () => void (isNew || moduleComponentsStore.loadToEdit(moduleComponentId)),
    [moduleComponentsStore, isNew, moduleComponentId]
  );

  const handleSubmit = useCallback(async (data) => {
    if (isNew) {
      await moduleComponentsStore.create({module: moduleId, step: moduleStepId, ...data});
    } else {
      await moduleComponentsStore.update(moduleComponentId, data);
    }

    location.navigate(`/modules/${moduleId}/steps/${moduleStepId}/components`);
  }, [isNew, location, moduleId, moduleStepId, moduleComponentsStore, moduleComponentId]);

  const {itemToEdit, createdItem, updatedItem} = moduleComponentsStore;
  const pending = itemToEdit.state === 'pending' ||
  createdItem.state === 'pending' ||
  updatedItem.state === 'pending';

  const renderForm = () => {
    if (isNew) {
      return (
        <ModuleComponentForm onSubmit={handleSubmit} />
      );
    }

    return itemToEdit.case({
      fulfilled: (value) => value
        ? <ModuleComponentForm values={value} onSubmit={handleSubmit} />
        : null,
      rejected: (error) => (<div>An error occurred: {error + ''}</div>)
    });
  };

  return (
    <Page title={isNew ? 'Add Module Component' : 'Edit Module Component'}>
      {pending ? <LoadingOverlay size={42} /> : null}
      {renderForm()}
    </Page>
  );
};

export default observer(ModuleComponentEditPage);
