import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileField from './../fields/FileField';

const AudioFieldset = ({name, targetFile}) => {
  const [{value}] = useField({name});

  const file = get(value, 'file');
  const fileName = get(value, 'file.name', get(value, 'file_name'));

  const [link, setLink] = useState(get(value, 'link'));

  useEffect(() => {
    file && setLink(file.url);
  }, [file]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Player link={link} fileName={fileName} />
      </Grid>
      <Grid item xs={12}>
        <FileField name={`${name}.file`} inputProps={{accept: 'audio/mp3'}}
          target={targetFile} label='Select Audio'
        />
      </Grid>
    </Grid>
  );
};

const Player = ({link, fileName}) => {
  return (
    <>
      <Typography variant='caption'>
        {fileName || 'Not Selected'}
      </Typography>
      <div />
      <audio controls key={link ? link.length : 0}>
        <source src={link} />
      </audio>
    </>
  );
};

export default AudioFieldset;
