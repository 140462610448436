import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useParams, useLocation} from './../../../hooks/router';

const ModuleComponentsIndexPage = () => {
  const {moduleComponentsStore} = useContext(RootStoreContext);
  const {moduleId, moduleStepId} = useParams();
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = moduleComponentsStore.getCollection();
    collection.setFilter({module: moduleId, step: moduleStepId});
    return collection;
  }, [moduleComponentsStore, moduleId, moduleStepId]);

  const columns = useMemo(
    () => ([{
      title: 'Title',
      field: 'title'
    }, {
      title: 'Subtitle',
      field: 'subtitle'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<ArrowBackIcon />),
      tooltip: 'Back to Steps',
      isFreeAction: true,
      alignLeft: true,
      onClick: () => location.navigate(`/modules/${moduleId}/steps`)
    }, {
      icon: () => (<AddIcon />),
      tooltip: 'Add Module Component',
      isFreeAction: true,
      onClick: () => location.navigate(`/modules/${moduleId}/steps/${moduleStepId}/components/new`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit Module Component',
      onClick: (e, component) => {
        location.navigate(`/modules/${moduleId}/steps/${moduleStepId}/components/${component._id}`);
      }
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete Module Component',
      onClick: async (e, component) => {
        if (window.confirm(
          `Are you sure you want to delete module component "${component.title}"?`
        )) {
          await moduleComponentsStore.delete(component._id);
          await collection.load();
        }
      }
    }]),
    [location, moduleId, moduleStepId, moduleComponentsStore, collection]
  );

  return (
    <Page title='Module Components'>
      <EntityList title='Module Components'
        columns={columns} actions={actions} collection={collection}
      />
    </Page>
  );
};

export default observer(ModuleComponentsIndexPage);
