import React from 'react';
import {useField} from 'formik';
import MUITextField from '@material-ui/core/TextField';

const TextField = ({name, helper = '', ...props}) => {
  const [{value, ...field}, meta] = useField({name});

  const helperText = meta.touched ? (meta.error || helper) : helper;

  return (
    <MUITextField {...props} {...field} id={name}
      value={value || ''}
      error={!!(meta.touched && meta.error)}
      helperText={helperText}
    />
  );
};

export default TextField;
