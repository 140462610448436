import HomeIcon from '@material-ui/icons/Home';
import GroupIcon from '@material-ui/icons/Group';
import StorageIcon from '@material-ui/icons/Storage';
import TableChartIcon from '@material-ui/icons/TableChart';
import GymIcon from '@material-ui/icons/FitnessCenter';
import SettingsIcon from '@material-ui/icons/Settings';

const menu = [{
  label: 'Dashboard',
  Icon: HomeIcon,
  link: '/',
  exact: true
}, {
  label: 'Users',
  Icon: GroupIcon,
  link: '/users'
}, {
  label: 'Module Categories',
  Icon: StorageIcon,
  link: '/module_categories'
}, {
  label: 'Modules',
  Icon: TableChartIcon,
  link: '/modules'
}, {
  label: 'Gym',
  Icon: GymIcon,
  link: '/gym_categories'
}, {
  label: 'Settings',
  Icon: SettingsIcon,
  link: '/settings'
}];

export default menu;
