import {decorate, observable, action} from 'mobx';
import {fromPromise} from 'mobx-utils';

export default (httpAgent, commonStore) => {
  return decorate({
    settings: fromPromise.resolve(null),
    updatedSettings: fromPromise.resolve(null),

    load () {
      this.settings = fromPromise(httpAgent.Settings.get());

      return this.settings;
    },

    async update (data) {
      this.updatedSettings = fromPromise(httpAgent.Settings.update(data));

      await this.updatedSettings;

      this.settings = this.updatedSettings;

      commonStore.addNotification(`Settings successfully updated`, 'success');
    },

    async flushCache () {
      await httpAgent.Cache.delete();

      commonStore.addNotification(`Cache successfully flushed`, 'success');
    }
  }, {
    settings: observable,
    updatedSettings: observable,
    load: action,
    update: action
  });
};
