import React from 'react';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from './../fields/TextField';
import Form from './../Form';

const schema = Yup.object().shape({
  subscription: Yup.object().shape({
  })
});

const initialValues = {
};

const NotificationsSettingsForm = ({onSubmit, values}) => {
  return (
    <Form {...{onSubmit, schema}} values={values || initialValues} patch={!!values}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Daily Reminder Notifications' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='daily_notification.time' label='Time'
                    helper='The format is HH:mm (24), for example 13:00' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='daily_notification.notification.title' label='Title' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
                    name='daily_notification.notification.body' label='Body'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

export default NotificationsSettingsForm;
