import React, {useCallback} from 'react';
import * as Yup from 'yup';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import TextField from './../fields/TextField';
import ArraySubDocumentField from './../fields/ArraySubDocumentField';
import Form from './../Form';

const schema = Yup.object().shape({
  subscription: Yup.object().shape({
  })
});

const initialValues = {
};

const InstructionsSettingsForm = ({onSubmit, values}) => {
  const getFeatureTitle = useCallback((feature) => feature + '', []);
  const initNewFeature = useCallback(() => '', []);

  return (
    <Form {...{onSubmit, schema}} values={values || initialValues} patch={!!values}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Content' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name='subscription.title' label='Title' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
                    name='subscription.subtitle' label='Subtitle'
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArraySubDocumentField label='Feature' title='Features'
                    name='subscription.features' editForm={FeatureEditForm}
                    getTitle={getFeatureTitle} initNewItem={initNewFeature}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
                    name='subscription.proposition' label='Proposition'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name='subscription.button' label='Button Label' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
                    name='subscription.price_text' label='Price Text'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth type='Number' name='subscription.price' label='Price' />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Form>
  );
};

const FeatureEditForm = ({name}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
          name={name} label='Feature'
        />
      </Grid>
    </Grid>
  );
};

export default InstructionsSettingsForm;
