import {decorate, autorun, observable, action} from 'mobx';
import id from 'lodash/uniqueId';

const STORE_KEY = 'pi_pq_common_store';

export default (store) => {
  const commonStore = decorate({
    isDrawerOpened: false,

    notifications: [],

    get asJson () {
      return {
        isDrawerOpened: this.isDrawerOpened
      };
    },

    fromJson (json) {
      this.isDrawerOpened = json.isDrawerOpened || false;
    },

    addNotification (text, variant = 'info') {
      this.notifications.push({id: id(), text, variant});
    },

    removeNotification (id) {
      this.notifications = this.notifications.filter((notification) => notification.id !== id);
    },

    openDrawer () {
      this.isDrawerOpened = true;
    },

    closeDrawer () {
      this.isDrawerOpened = false;
    }
  }, {
    isDrawerOpened: observable.ref,
    notifications: observable,
    addNotification: action,
    removeNotification: action,
    openDrawer: action,
    closeDrawer: action
  });

  if (store) {
    commonStore.fromJson(store.get(STORE_KEY) || {});

    autorun(() => {
      store.set(STORE_KEY, commonStore.asJson);
    });
  }

  return commonStore;
};
