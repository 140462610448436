import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const CacheSettingsForm = ({onFlushCache}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button variant='contained' color='primary' onClick={onFlushCache}>
          Flush Cache
        </Button>
      </Grid>
    </Grid>
  );
};

export default CacheSettingsForm;
