import {decorate, autorun, observable, computed, action, transaction} from 'mobx';

const STORE_KEY = 'pi_pq_auth_store';

export default (httpAgent, store) => {
  const authStore = decorate({
    token: null,
    refreshToken: null,
    isLoading: false,
    user: {},

    get asJson () {
      return {
        token: this.token,
        refreshToken: this.refreshToken
      };
    },

    fromJson (json) {
      this.token = json.token || null;
      this.refreshToken = json.refreshToken || null;
    },

    get authenticated () {
      return !!this.token;
    },

    async login (login, password) {
      await this.getToken({login, password});
    },

    async refresh () {
      await this.getToken({refresh_token: this.refreshToken});
    },

    async getToken (credentials) {
      this.isLoading = true;

      try {
        const tokens = await httpAgent.Auth.login(credentials);

        transaction(() => {
          this.isLoading = false;
          this.token = tokens.token;
          this.refreshToken = tokens.refresh_token;
        });

        await this.loadUser();
      } catch (err) {
        this.isLoading = false;

        throw err;
      }
    },

    async loadUser () {
      this.isLoading = true;

      try {
        const user = await httpAgent.Auth.current();

        transaction(() => {
          this.isLoading = false;
          this.user = user;
        });
      } catch (err) {
        this.isLoading = false;

        throw err;
      }
    },

    logout () {
      this.token = null;
      this.refreshToken = null;
      this.user = {};
    }
  }, {
    token: observable.ref,
    refreshToken: observable.ref,
    isLoading: observable.ref,
    user: observable.ref,
    asJson: computed,
    authenticated: computed,
    login: action,
    refresh: action,
    getToken: action,
    loadUser: action,
    logout: action
  });

  if (httpAgent) {
    httpAgent.setAuthenticator(authStore);
  }

  if (store) {
    authStore.fromJson(store.get(STORE_KEY) || {});

    if (authStore.authenticated) {
      authStore.loadUser();
    }

    autorun(() => {
      store.set(STORE_KEY, authStore.asJson);
    });
  }

  return authStore;
};
