import React, {useContext, useLayoutEffect, useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import LoadingOverlay from './../../LoadingOverlay';
import OnboardingSettingsForm from './../../forms/settings/OnboardingSettingsForm';
import SaboteurSettingsForm from './../../forms/settings/SaboteurSettingsForm';
import ScoreSystemSettingsForm from './../../forms/settings/ScoreSystemSettingsForm';
import SubscriptionSettingsForm from './../../forms/settings/SubscriptionSettingsForm';
import NotificationsSettingsForm from './../../forms/settings/NotificationsSettingsForm';
import CacheSettingsForm from './../../forms/settings/CacheSettingsForm';
import {useParams} from './../../../hooks/router';

const SettingsEditPage = () => {
  const {category = 'onboarding'} = useParams();
  const {settingsStore} = useContext(RootStoreContext);

  useLayoutEffect(
    () => void (settingsStore.load()),
    [settingsStore]
  );

  const tabs = useMemo(() => ([{
    label: 'Onboarding',
    href: '/settings/onboarding'
  }, {
    label: 'Saboteur Descriptions',
    href: '/settings/saboteur_descriptions'
  }, {
    label: 'Score System',
    href: '/settings/score_system'
  }, {
    label: 'Subscription',
    href: '/settings/subscription'
  }, {
    label: 'Notifications',
    href: '/settings/notifications'
  }, {
    label: 'Cache',
    href: '/settings/cache'
  }]), []);

  const handleSubmit = useCallback(async (data) => {
    await settingsStore.update(data);
    await settingsStore.load();
  }, [settingsStore]);

  const {settings, updatedSettings, flushCache} = settingsStore;
  const pending = settings.state === 'pending' ||
  updatedSettings.state === 'pending';

  const renderForm = (value) => {
    switch (category) {
      case 'onboarding':
        return (
          <OnboardingSettingsForm values={value} onSubmit={handleSubmit} />
        );
      case 'saboteur_descriptions':
        return (
          <SaboteurSettingsForm values={value} onSubmit={handleSubmit} />
        );
      case 'score_system':
        return (
          <ScoreSystemSettingsForm values={value} onSubmit={handleSubmit} />
        );
      case 'subscription':
        return (
          <SubscriptionSettingsForm values={value} onSubmit={handleSubmit} />
        );
      case 'notifications':
        return (
          <NotificationsSettingsForm values={value} onSubmit={handleSubmit} />
        );
      case 'cache':
        return (
          <CacheSettingsForm onFlushCache={flushCache} />
        );
      default:
        return null;
    }
  };

  return (
    <Page title='Settings' tabs={tabs}>
      {pending ? <LoadingOverlay size={42} /> : null}
      {settings.case({
        fulfilled: (value) => value ? renderForm(value) : null,
        rejected: (error) => (<div>An error occurred: {error + ''}</div>)
      })}
    </Page>
  );
};

export default observer(SettingsEditPage);
