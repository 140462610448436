import React from 'react';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Form from './Form';
import TextField from './fields/TextField';

const schema = Yup.object().shape({
  title: Yup.string().required()
});

const initialValues = {
  title: ''
};

const GymCategoryForm = ({onSubmit, values}) => {
  return (
    <Form {...{onSubmit, schema}} values={values || initialValues} patch={!!values}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth name='title' label='Title' />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default GymCategoryForm;
