import React, {useContext, useMemo} from 'react';
import * as Yup from 'yup';
import ObjectID from 'bson-objectid';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RootStoreContext from './../../contexts/RootStoreContext';
import Form from './Form';
import TextField from './fields/TextField';
import SwitchField from './fields/SwitchField';
import SelectField from './fields/SelectField';
import ImageFieldset from './fieldsets/ImageFieldset';

const schema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required()
});

const initialValues = {
  title: '',
  is_intro: false,
  saveable: false,
  category: null
};

const ModuleForm = ({onSubmit, values}) => {
  const {moduleCategoriesStore} = useContext(RootStoreContext);
  const module = useMemo(() => values || {_id: new ObjectID() + '', ...initialValues}, [values]);

  const categoriesCollection = useMemo(() => {
    const collection = moduleCategoriesStore.getCollection();
    collection.load();
    return collection;
  }, [moduleCategoriesStore]);

  return (
    <Form {...{onSubmit, schema}} values={module} patch={!!values}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SwitchField name='is_intro' label='Is Intro' />
            </Grid>
            <Grid item xs={12}>
              <SwitchField name='saveable' label='Saveable' />
            </Grid>
            <Grid item xs={12}>
              <SelectField name='category' label='Category' collection={categoriesCollection}
                valueField='_id' labelField='title' isClearable
              />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth name='title' label='Title' />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                name='description' label='Description'
              />
            </Grid>
            <Grid item xs={12}>
              <ImageFieldset name='cover' label='cover'
                targetFile={`module-${module._id}-cover`}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default ModuleForm;
