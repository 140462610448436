import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useParams, useLocation} from './../../../hooks/router';

const GymItemsIndexPage = () => {
  const {gymItemsStore} = useContext(RootStoreContext);
  const {gymCategoryId} = useParams();
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = gymItemsStore.getCollection();
    collection.setFilter({category: gymCategoryId});
    return collection;
  }, [gymCategoryId, gymItemsStore]);

  const columns = useMemo(
    () => ([{
      title: 'Title',
      field: 'title'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<ArrowBackIcon />),
      tooltip: 'Back to Gym Categories',
      isFreeAction: true,
      alignLeft: true,
      onClick: () => location.navigate(`/gym_categories`)
    }, {
      icon: () => (<AddIcon />),
      tooltip: 'Add Gym Item',
      isFreeAction: true,
      onClick: () => location.navigate(`/gym_categories/${gymCategoryId}/items/new`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit Gym Item',
      onClick: (e, gymItem) => location.navigate(
        `/gym_categories/${gymCategoryId}/items/${gymItem._id}`
      )
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete Gym Item',
      onClick: async (e, gymItem) => {
        if (window.confirm(
          `Are you sure you want to delete Gym Item "${gymItem.title}"?`
        )) {
          await gymItemsStore.delete(gymItem._id);
          await collection.load();
        }
      }
    }]),
    [collection, location, gymCategoryId, gymItemsStore]
  );

  return (
    <Page title='Gym Items'>
      <EntityList title='Gym Items'
        columns={columns} actions={actions} collection={collection}
      />
    </Page>
  );
};

export default observer(GymItemsIndexPage);
