import React, {useCallback} from 'react';
import capitalize from 'lodash/capitalize';
import * as Yup from 'yup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import scoreTypes from './../../../enum/scoreTypes';
import Form from './../Form';
import TextField from './../fields/TextField';
import ArraySubDocumentField from './../fields/ArraySubDocumentField';

const schema = Yup.object().shape({
});

const initialValues = {
};

const ScoreSystemSettingsForm = ({onSubmit, values}) => {
  const getLevelTitle = useCallback((level, index) => {
    return `Level #${index + 1} (${level.min_score})`;
  }, []);

  return (
    <Form {...{onSubmit, schema}} values={values || initialValues} patch={!!values}>
      {Object.values(scoreTypes).map((scoreType) => (
        <ExpansionPanel key={scoreType}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
            <Typography variant='h5'>{capitalize(scoreType.replace(/_/g, ' '))}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArraySubDocumentField label='Level' title='Levels'
                  name={`score_levels.${scoreType}`} editForm={LevelEditForm}
                  getTitle={getLevelTitle}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Form>
  );
};

const LevelEditForm = ({name}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth name={`${name}.min_score`} label='Minimum Score'
          helper='Minimum score required to get the level' />
      </Grid>
    </Grid>
  );
};

export default ScoreSystemSettingsForm;
