import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import get from 'lodash/get';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FileField from './../fields/FileField';

const useStyles = makeStyles(() => ({
  preview: {
    height: 150,
    width: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.05)'
  }
}));

const ImageFieldset = ({name, targetFile}) => {
  const [{value}] = useField({name});

  const file = get(value, 'file');
  const fileName = get(value, 'file.name', get(value, 'file_name'));

  const [link, setLink] = useState(get(value, 'link'));

  useEffect(() => {
    file && setLink(file.url);
  }, [file]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Cover</Typography>
      </Grid>
      <Grid item xs={12}>
        <Preview link={link} fileName={fileName} />
      </Grid>
      <Grid item xs={12}>
        <FileField name={`${name}.file`} inputProps={{accept: 'image/*'}}
          target={targetFile} label='Select Image'
        />
      </Grid>
    </Grid>
  );
};

const Preview = ({link, fileName}) => {
  const classes = useStyles();

  return (
    <>
      {link ? <img className={classes.preview} src={link} alt='' /> : null}
      <div />
      <Typography variant='caption'>
        {fileName || 'Not Selected'}
      </Typography>
    </>
  );
};

export default ImageFieldset;
