import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import get from 'lodash/get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LoadingOverlay from './../LoadingOverlay';
import EntityListToolbar from './Toolbar';
import Action from './Action';

const EntityList = ({title, collection, columns, actions}) => {
  const handleQuery = useCallback(
    (query) => collection.setQuery(query),
    [collection]
  );

  const handleChangePage = useCallback(
    (e, page) => collection.setPage(page),
    [collection]
  );

  const handleChangePerPage = useCallback(
    (e) => collection.setPerPage(+e.target.value),
    [collection]
  );

  const freeActions = (actions || []).filter((action) => action.isFreeAction);
  const rowActions = (actions || []).filter((action) => !action.isFreeAction);

  const totalColumns = columns.length + (rowActions.length ? 1 : 0);

  const renderList = (values) => {
    const items = get(values, 'data', []);
    const page = get(values, 'page', 0);
    const perPage = get(values, 'perPage', 0);
    const total = get(values, 'total', 0);
    const query = get(values, 'query', '');

    return (
      <Paper>
        <EntityListToolbar {...{title, query}} onQuery={handleQuery} actions={freeActions} />
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.title}>
                  {column.title}
                </TableCell>
              ))}
              {rowActions.length
                ? <TableCell>
                Actions
                </TableCell>
                : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item._id}>
                {columns.map((column) => (
                  <TableCell key={column.title}>
                    {item[column.field]}
                  </TableCell>
                ))}
                {rowActions.length
                  ? <TableCell size='small' padding='none' width={48 * (rowActions.length + 1)}>
                    {rowActions.map((action, i) => (
                      <Action key={`action-${i}`} action={action} data={item} />
                    ))}
                  </TableCell>
                  : null}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[2, 20, 30]}
                colSpan={totalColumns}
                count={total}
                rowsPerPage={perPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangePerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  };

  return (
    <React.Fragment>
      {collection.items.case({
        pending: () => (<LoadingOverlay size={42} />),
        fulfilled: () => null,
        rejected: () => null
      })}
      {collection.items.case({
        pending: (staleValue) => renderList(staleValue),
        fulfilled: (value) => renderList(value),
        rejected: (error) => (<div>An error occurred: {error + ''}</div>)
      })}
    </React.Fragment>
  );
};

export default observer(EntityList);
