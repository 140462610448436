import {createCRUDStore, uploadAudio} from './utils';

export default (httpAgent, commonStore, filesStore) => {
  const uploadFiles = async (data) => {
    let result = {...data};

    if (data.data && data.data.file) {
      result.data = await uploadAudio(data.data, filesStore);
    }

    return result;
  };

  return createCRUDStore(
    httpAgent.GymItems, commonStore, 'Gym Item',
    {
      beforeCreate: uploadFiles,
      beforeUpdate: uploadFiles
    }
  );
};
