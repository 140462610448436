import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 9999999,
    textAlign: 'center'
  },
  progress: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  }
}));

const LoadingOverlay = ({size}) => {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <div className={classes.progress}>
        <CircularProgress size={size} color='inherit' />
      </div>
    </div>
  );
};

export default LoadingOverlay;
