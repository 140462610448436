import React, {useMemo} from 'react';
import * as Yup from 'yup';
import ObjectID from 'bson-objectid';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Form from './Form';
import TextField from './fields/TextField';
import AudioFieldset from './fieldsets/AudioFieldset';

const schema = Yup.object().shape({
  title: Yup.string().required()
});

const initialValues = {
  title: ''
};

const GymItemForm = ({onSubmit, values}) => {
  const item = useMemo(() => values || {_id: new ObjectID() + '', ...initialValues}, [values]);

  return (
    <Form {...{onSubmit, schema}} values={item} patch={!!values}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth name='title' label='Title' />
            </Grid>
            <Grid item xs={12}>
              <AudioFieldset name='data' targetFile={`gym-item-${item._id}-data`} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

export default GymItemForm;
