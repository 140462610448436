import React, {useCallback, useMemo, useContext} from 'react';
import ObjectId from 'bson-objectid';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import * as Yup from 'yup';
import {useField} from 'formik';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import RootStoreContext from './../../../contexts/RootStoreContext';
import types from './../../../enum/moduleComponentTypes';
import saboteurs from './../../../enum/saboteurs';
import TextField from './../fields/TextField';
import SwitchField from './../fields/SwitchField';
import ArraySubDocumentField from './../fields/ArraySubDocumentField';
import SelectField from './../fields/SelectField';
import HideableField from './../fields/HideableField';
import AudioFieldset from './AudioFieldset';

const saboteursWithoutJudge = Object.values(saboteurs)
  .filter((saboteur) => saboteur !== saboteurs.JUDGE);

const formatTypeTitle = (title = '') => startCase(camelCase(title.replace(/_/g, ' ')));

const setDataFields = (dataSet) => {
  const data = {
    video: null,
    audio: null,
    saboteurs_audio: null,
    multi_select_cards: null,
    checkin: null,
    multi_checkin: null,
    routine_selector: null,
    gym_practice: null
  };

  switch (dataSet.type) {
    case types.AUDIO:
      data.audio = dataSet.data.audio;
      break;
    case types.VIDEO:
      data.video = dataSet.data.video;
      break;
    case types.SABOTEURS_AUDIO:
      data.saboteurs_audio = dataSet.data.saboteurs_audio;
      break;
    case types.MULTI_SELECT_CARDS:
      data.multi_select_cards = dataSet.data.multi_select_cards;
      break;
    case types.CHECKIN:
      data.checkin = dataSet.data.checkin;
      break;
    case types.MULTI_CHECKIN:
      data.multi_checkin = dataSet.data.multi_checkin;
      break;
    case types.ROUTINE_SELECTOR:
      data.routine_selector = dataSet.data.routine_selector;
      break;
    case types.GYM_PRACTICE:
      data.gym_practice = dataSet.data.gym_practice;
      break;
    default:
      break;
  }

  return {...dataSet, data};
};

export const schema = Yup.array().of(
  Yup.object().shape({
    type: Yup.string().required()
  })
);

export const output = (value) => (value || []).map((value) => ({
  ...setDataFields(value)
}));

const ComponentDataFieldset = ({name}) => {
  const initNewDataSet = useCallback(() => ({_id: new ObjectId() + '', data: {}}), []);
  const getDataSetTitle = useCallback((dataSet, i) => {
    const type = formatTypeTitle(dataSet.type) || 'No Type';
    return `Data Set # ${i + 1} (${type})`;
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ArraySubDocumentField label='Data Set' title='Data Sets'
          name={name} initNewItem={initNewDataSet} autoEdit={0}
          editForm={DataSetEditForm} getTitle={getDataSetTitle}
        />
      </Grid>
    </Grid>
  );
};

const DataSetEditForm = ({name}) => {
  const [{value: id}] = useField({name: `${name}._id`});
  const {moduleComponentsStore, gymItemsStore} = useContext(RootStoreContext);

  const initNewActionItem = useCallback(() => ({_id: new ObjectId() + ''}), []);
  const initNewCardItem = useCallback(() => ({_id: new ObjectId() + ''}), []);
  const initRoutineSelectorItem = useCallback(() => ({_id: new ObjectId() + ''}), []);

  const getCardItemTitle = useCallback((item) => item.label || 'No Label', []);
  const getActionItemTitle = useCallback((item) => item.label || 'No Label', []);
  const getRoutineSelectorItemTitle = useCallback((item) => {
    const label = item.label || 'No Label';
    const icon = item.icon || '∅';

    return `${icon} ${label}`;
  }, []);

  const otherComponentsCollection = useMemo(
    () => moduleComponentsStore.getCollection(),
    [moduleComponentsStore]
  );

  const gymItemsCollection = useMemo(
    () => gymItemsStore.getCollection(),
    [gymItemsStore]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <SelectField name={`${name}.type`} label='Type'
          options={Object.values(types).map((type) => (
            {value: type, label: formatTypeTitle(type)}
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.has_condition`} label='With Condition' />
      </Grid>
      <HideableField ifName={`${name}.has_condition`}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Condition' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectField name={`${name}.condition.component`} label='Component'
                    collection={otherComponentsCollection} loadOnMount
                    valueField='_id' labelField='subtitle' isClearable
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField fullWidth name={`${name}.condition.value`} label='Value' />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.VIDEO}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Video' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.video.vimeo_id`}
                    type='number' label='Vimeo Id'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.AUDIO}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Audio' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <AudioFieldset name={`${name}.data.audio`}
                    targetFile={`component-dataSet-${id}-audio`}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.SABOTEURS_AUDIO}>
        {saboteursWithoutJudge.map((saboteur) => (
          <Grid item xs={12} key={saboteur}>
            <Card>
              <CardHeader title={capitalize(saboteur.replace(/_/g, '-'))} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AudioFieldset
                      name={`${name}.data.saboteurs_audio.saboteurs.${saboteur}.audio`}
                      targetFile={`component-dataSet-${id}-${saboteur}-audio`}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.MULTI_SELECT_CARDS}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Cards' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.multi_select_cards.min_items`}
                    type='number' label='Min Items'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.multi_select_cards.max_items`}
                    type='number' label='Max items'
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArraySubDocumentField label='Item' title='Items'
                    name={`${name}.data.multi_select_cards.items`}
                    initNewItem={initNewCardItem}
                    getTitle={getCardItemTitle} editForm={CardEditForm}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.CHECKIN}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Check In' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.checkin.counter.target`}
                    label='Counter Target' type='number'
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField name={`${name}.data.checkin.counter.description_from`}
                    label='Description From Component' collection={otherComponentsCollection}
                    loadOnMount valueField='_id' labelField='subtitle' isClearable
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.checkin.action.label`}
                    label='Action Label'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                    name={`${name}.data.checkin.action.description`}
                    label='Action Description'
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.MULTI_CHECKIN}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Multi Check In' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.multi_checkin.counter.target`}
                    type='number' label='Counter Target'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='2' variant='outlined'
                    name={`${name}.data.multi_checkin.action.description`}
                    label='Action Description'
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArraySubDocumentField label='Action Item' title='Action Items'
                    name={`${name}.data.multi_checkin.action.items`}
                    initNewItem={initNewActionItem} getTitle={getActionItemTitle}
                    editForm={ActionItemEditForm}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.ROUTINE_SELECTOR}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Routine Selector' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.routine_selector.min_items`}
                    type='number' label='Min Items'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.routine_selector.max_items`}
                    type='number' label='Max items'
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArraySubDocumentField label='Routine' title='Routines'
                    name={`${name}.data.routine_selector.routines`}
                    initNewItem={initRoutineSelectorItem} getTitle={getRoutineSelectorItemTitle}
                    editForm={RoutineSelectorItemEditForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArraySubDocumentField label='PQ Reps' title='PQ Reps'
                    name={`${name}.data.routine_selector.pq_reps`}
                    initNewItem={initRoutineSelectorItem} getTitle={getRoutineSelectorItemTitle}
                    editForm={RoutineSelectorItemEditForm}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
      <HideableField ifName={`${name}.type`} equal={types.GYM_PRACTICE}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title='Gym Practice' />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${name}.data.gym_practice.counter.target`}
                    type='number' label='Counter Target'
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField name={`${name}.data.gym_practice.gym_items`}
                    collection={gymItemsCollection} loadOnMount label='Suggested Gym Items'
                    valueField='_id' labelField='title' isClearable isMulti
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </HideableField>
    </Grid>
  );
};

const CardEditForm = ({name}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.label`} label='Label' />
    </Grid>
  </Grid>
);

const ActionItemEditForm = ({name}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.label`} label='Label' />
    </Grid>
    <Grid item xs={12} lg={6}>
      <SelectField name={`${name}.icon`} label='Icon'
        options={['self', 'others', 'circumstances', 'knowledge', 'inspiration', 'power']
          .map((type) => ({value: type, label: capitalize(type.replace(/_/g, ' '))}))}
      />
    </Grid>
  </Grid>
);

const RoutineSelectorItemEditForm = ({name}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.icon`} label='Icon' />
    </Grid>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.label`} label='Label' />
    </Grid>
  </Grid>
);

export default ComponentDataFieldset;
