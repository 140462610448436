import {extendObservable} from 'mobx';
import {createCRUDStore} from './utils';

export default (httpAgent, commonStore) => {
  return extendObservable(
    createCRUDStore(httpAgent.Users, commonStore, 'User'),
    {
      async deleteProgress (id) {
        await httpAgent.Users.deleteProgress(id);

        commonStore.addNotification(`Progress successfully deleted`, 'success');
      },

      async createNotification (id, data) {
        await httpAgent.Users.createNotification(id, data);

        commonStore.addNotification(`Notification successfully sent`, 'success');
      }
    }
  );
};
