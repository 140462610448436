import React, {useCallback} from 'react';
import {useField} from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchField = ({name, label, ...props}) => {
  const [{value, onChange, ...field}] = useField({name});

  const handleChange = useCallback((e) => {
    onChange({
      target: {
        name: e.target.name,
        value: e.target.checked
      }
    });
  }, [onChange]);

  return (
    <FormControlLabel
      control={
        <Switch {...field} checked={!!value} onChange={handleChange} />
      }
      label={label}
      {...props}
    />
  );
};

export default SwitchField;
