import React, {useContext, useLayoutEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import LoadingOverlay from './../../LoadingOverlay';
import GymCategoryForm from './../../forms/GymCategoryForm';
import {useParams, useLocation} from './../../../hooks/router';

const GymCategoryEditPage = () => {
  const {gymCategoriesStore} = useContext(RootStoreContext);
  const params = useParams();
  const location = useLocation();

  const isNew = params.gymCategoryId === 'new';

  useLayoutEffect(
    () => void (isNew || gymCategoriesStore.loadToEdit(params.gymCategoryId)),
    [params, isNew, gymCategoriesStore]
  );

  const handleSubmit = useCallback(async (data) => {
    if (isNew) {
      await gymCategoriesStore.create(data);
    } else {
      await gymCategoriesStore.update(params.gymCategoryId, data);
    }

    location.navigate('/gym_categories');
  }, [gymCategoriesStore, isNew, location, params.gymCategoryId]);

  const {itemToEdit, createdItem, updatedItem} = gymCategoriesStore;
  const pending = itemToEdit.state === 'pending' ||
  createdItem.state === 'pending' ||
  updatedItem.state === 'pending';

  const renderForm = () => {
    if (isNew) {
      return (
        <GymCategoryForm onSubmit={handleSubmit} />
      );
    }

    return itemToEdit.case({
      fulfilled: (value) => value
        ? <GymCategoryForm values={value} onSubmit={handleSubmit} />
        : null,
      rejected: (error) => (<div>An error occurred: {error + ''}</div>)
    });
  };

  return (
    <Page title={isNew ? 'Add Gym Category' : 'Edit Gym Category'}>
      {pending ? <LoadingOverlay size={42} /> : null}
      {renderForm()}
    </Page>
  );
};

export default observer(GymCategoryEditPage);
