import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useParams, useLocation} from './../../../hooks/router';

const ModuleStepsIndexPage = () => {
  const {moduleStepsStore} = useContext(RootStoreContext);
  const {moduleId} = useParams();
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = moduleStepsStore.getCollection();
    collection.setFilter({module: moduleId});
    return collection;
  }, [moduleId, moduleStepsStore]);

  const columns = useMemo(
    () => ([{
      title: 'Title',
      field: 'title'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<ArrowBackIcon />),
      tooltip: 'Back to Modules',
      isFreeAction: true,
      alignLeft: true,
      onClick: () => location.navigate(`/modules`)
    }, {
      icon: () => (<AddIcon />),
      tooltip: 'Add Module Step',
      isFreeAction: true,
      onClick: () => location.navigate(`/modules/${moduleId}/steps/new`)
    }, {
      icon: () => (<ListIcon />),
      tooltip: 'Edit Components',
      onClick: (e, step) => location.navigate(`/modules/${moduleId}/steps/${step._id}/components`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit Module Step',
      onClick: (e, step) => location.navigate(`/modules/${moduleId}/steps/${step._id}`)
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete Module Step',
      onClick: async (e, step) => {
        if (window.confirm(
          `Are you sure you want to delete module step "${step.title}"?`
        )) {
          await moduleStepsStore.delete(step._id);
          await collection.load();
        }
      }
    }]),
    [collection, location, moduleId, moduleStepsStore]
  );

  return (
    <Page title='Module Steps'>
      <EntityList title='Module Steps'
        columns={columns} actions={actions} collection={collection}
      />
    </Page>
  );
};

export default observer(ModuleStepsIndexPage);
