import {createCRUDStore} from './utils';

export default (httpAgent, commonStore, filesStore) => {
  const uploadFiles = async (data) => {
    let result = {...data};

    if (data && data.cover && data.cover.file) {
      const link = await filesStore.upload(data.cover.file);

      result.cover = {
        link,
        file_name: data.cover.file.name
      };
    }

    return result;
  };

  return createCRUDStore(
    httpAgent.Modules, commonStore, 'Module',
    {
      beforeCreate: uploadFiles,
      beforeUpdate: uploadFiles
    }
  );
};
