import React, {useCallback} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const Action = ({action: definition, data}) => {
  let action = definition;

  if (typeof action === 'function') {
    action = action(data);
  }

  const handleOnClick = useCallback((event) => {
    if (action.onClick) {
      action.onClick(event, data);
      event.stopPropagation();
    }
  }, [action, data]);

  if (action.hidden) {
    return null;
  }

  const button = (
    <span>
      <IconButton color='inherit' disabled={action.disabled}
        onClick={handleOnClick}
      >
        <action.icon size='small' disabled={action.disabled} />
      </IconButton>
    </span>
  );

  if (action.tooltip) {
    return <Tooltip title={action.tooltip}>{button}</Tooltip>;
  }

  return button;
};

export default Action;
