import React, {useContext, useLayoutEffect, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import LoadingOverlay from './../../LoadingOverlay';
import GymItemForm from './../../forms/GymItemForm';
import {useParams, useLocation} from './../../../hooks/router';

const GymItemEditPage = () => {
  const {gymItemsStore} = useContext(RootStoreContext);
  const {gymCategoryId, gymItemId} = useParams();
  const location = useLocation();

  const isNew = gymItemId === 'new';

  useLayoutEffect(
    () => void (isNew || gymItemsStore.loadToEdit(gymItemId)),
    [gymItemsStore, isNew, gymItemId]
  );

  const handleSubmit = useCallback(async (data) => {
    if (isNew) {
      await gymItemsStore.create({category: gymCategoryId, ...data});
    } else {
      await gymItemsStore.update(gymItemId, data);
    }

    location.navigate(`/gym_categories/${gymCategoryId}/items`);
  }, [isNew, location, gymCategoryId, gymItemId, gymItemsStore]);

  const {itemToEdit, createdItem, updatedItem} = gymItemsStore;
  const pending = itemToEdit.state === 'pending' ||
  createdItem.state === 'pending' ||
  updatedItem.state === 'pending';

  const renderForm = () => {
    if (isNew) {
      return (
        <GymItemForm onSubmit={handleSubmit} />
      );
    }

    return itemToEdit.case({
      fulfilled: (value) => value
        ? <GymItemForm values={value} onSubmit={handleSubmit} />
        : null,
      rejected: (error) => (<div>An error occurred: {error + ''}</div>)
    });
  };

  return (
    <Page title={isNew ? 'Add Gym Item' : 'Edit Gym Item'}>
      {pending ? <LoadingOverlay size={42} /> : null}
      {renderForm()}
    </Page>
  );
};

export default observer(GymItemEditPage);
