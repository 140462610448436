import React, {useContext, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {NavLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RootStoreContext from './../../contexts/RootStoreContext';
import {useLocation} from './../../hooks/router';
import TopBar from './TopBar';
import LeftDrawer from './LeftDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    // toolbar
    marginTop: theme.spacing(8)
  },
  container: {
    padding: theme.spacing(2)
  }
}));

const ForwardNavLink = React.forwardRef((props, ref) => (
  <NavLink {...props} innerRef={ref} />
));

const Page = ({children, title = 'PQ Admin Panel', tabs}) => {
  const {location: {pathname}} = useLocation();
  const classes = useStyles();
  const {commonStore} = useContext(RootStoreContext);

  const handleDrawerOpen = useCallback(() => commonStore.openDrawer(), [commonStore]);
  const handleDrawerClose = useCallback(() => commonStore.closeDrawer(), [commonStore]);

  const {isDrawerOpened} = commonStore;

  const renderTabs = () => {
    const activeTab = tabs.find(({href}) => href === pathname) || tabs[0];
    return (
      <AppBar position='static' color='default'>
        <Tabs value={activeTab.href}>
          {tabs.map((tab) => (
            <Tab key={tab.label} label={tab.label} to={tab.href}
              value={tab.href} component={ForwardNavLink}
            />
          ))}
        </Tabs>
      </AppBar>
    );
  };

  return (
    <div className={classes.root}>
      <TopBar title={title} isDrawerOpen={isDrawerOpened} onDrawerOpen={handleDrawerOpen} />
      <LeftDrawer open={isDrawerOpened} onClose={handleDrawerClose} />
      <main className={classes.content}>
        {tabs && renderTabs()}
        <div className={classes.container}>
          {children}
        </div>
      </main>
    </div>
  );
};

export default observer(Page);
