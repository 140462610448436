import React, {useState, useCallback} from 'react';
import debounce from 'lodash/debounce';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ResetIcon from '@material-ui/icons/Clear';
import Action from './Action';

const useStyles = makeStyles(() => ({
  spacer: {
    flexGrow: 1
  }
}));

const EntityListToolbar = ({title, actions, query, onQuery}) => {
  const classes = useStyles();

  const [queryBuffer, setQueryBuffer] = useState(query);

  const handleQueryChange = useCallback(({target: {value}}) => {
    const onQueryDebounced = debounce(onQuery, 500);

    setQueryBuffer(value);
    onQueryDebounced(value);
  }, [onQuery]);

  const handleReset = useCallback(() => {
    onQuery('');
  }, [onQuery]);

  const leftActions = actions.filter(({alignLeft}) => alignLeft);
  const rightActions = actions.filter(({alignLeft}) => !alignLeft);

  return (
    <Toolbar>
      {leftActions.length
        ? <div className={classes.actions}>
          <div>
            {leftActions.map((action, i) => (
              <Action key={`action-${i}`} action={action} />
            ))}
          </div>
        </div>
        : null}
      <div>
        <Typography variant='h6'>{title}</Typography>
      </div>
      <div className={classes.spacer} />
      <TextField
        value={queryBuffer}
        onChange={handleQueryChange}
        placeholder='Search'
        color='inherit'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Tooltip title='Search'>
                <SearchIcon color='inherit' fontSize='small' />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={!queryBuffer}
                onClick={handleReset}
              >
                <ResetIcon color='inherit' fontSize='small' />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {rightActions.length
        ? <div className={classes.actions}>
          <div>
            {rightActions.map((action, i) => (
              <Action key={`action-${i}`} action={action} />
            ))}
          </div>
        </div>
        : null}
    </Toolbar>
  );
};

export default EntityListToolbar;
