import React, {useContext, useState} from 'react';
import {observer} from 'mobx-react-lite';
import truncate from 'lodash/truncate';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import LoadingOverlay from './../LoadingOverlay';
import RootStoreContext from './../../contexts/RootStoreContext';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: theme.spacing(1)
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1)
  }
}));

const AuthBar = () => {
  const classes = useStyles();
  const {authStore} = useContext(RootStoreContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    authStore.logout();
    handleClose();
  };

  const username = (!authStore.loading && authStore.user.login) ? authStore.user.login : '';

  return (
    <div className={classes.container}>
      {authStore.loading ? <LoadingOverlay size={24} /> : null}
      <Button onClick={handleUserClick} color='inherit'>
        {truncate(username, {length: 40})}
      </Button>
      <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default observer(AuthBar);
