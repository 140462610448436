import first from 'lodash/first';
const qs = require('query-string');

export default (baseUrl) => {
  const http = {
    get (url) {
      return this.makeApiRequest('get', url);
    },

    post (url, body) {
      return this.makeApiRequest('post', url, body);
    },

    delete (url) {
      return this.makeApiRequest('delete', url);
    },

    async makeApiRequest (method, url, body) {
      const {authenticator} = this;

      const makeRequest = async (retry) => {
        const headers = {
          'Content-Type': 'application/json'
        };

        if (authenticator && authenticator.authenticated && url !== 'auth/tokens') {
          headers['X-Access-Token'] = authenticator.token;
        }

        const result = await fetch(`${baseUrl}/v1.0/${url}`, {
          method,
          mode: 'cors',
          cache: 'no-cache',
          headers,
          body: body ? JSON.stringify(body) : undefined
        });

        let data = {};

        try {
          data = await result.json();
        } catch (err) {}

        if (!result.ok) {
          if (result.status === 401 && !retry && authenticator) {
            await authenticator.refresh();

            return makeRequest(true);
          }

          const apiError = new Error('API Error');

          apiError.status = result.status;
          apiError.data = data;
          apiError.code = data && data.code;
          apiError.message = data && data.message;
          apiError.meta = data && data.meta;
          apiError.url = url;

          throw apiError;
        }

        return {data, headers: result.headers};
      };

      return makeRequest();
    }
  };

  const createCRUDMethods = (baseUrl) => ({
    async getAll ({query = '', page = 0, perPage = '', filter = {}}) {
      const url = `${baseUrl}?${qs.stringify({query, page, per_page: perPage, ...filter})}`;
      const {data, headers} = await http.get(url);

      const total = parseInt(headers.get('x-total'), 10);

      return {data, total};
    },

    async getById (id) {
      const {data: items} = await http.get(`${baseUrl}?query=${id}`);

      return first(items);
    },

    async create (data) {
      const {data: createdItem} = await http.post(`${baseUrl}`, data);

      return createdItem;
    },

    async updateById (id, data) {
      const {data: updatedItem} = await http.post(`${baseUrl}/${id}`, data);

      return updatedItem;
    },

    async deleteById (id) {
      await http.delete(`${baseUrl}/${id}`);
    }
  });

  return {
    setAuthenticator (authenticator) {
      http.authenticator = authenticator;
    },

    Auth: {
      async current () {
        const {data: user} = await http.get('users/me');

        return user;
      },

      async login (credentials) {
        const {data: tokens} = await http.post('auth/tokens', credentials);

        return tokens;
      }
    },

    Settings: {
      async get () {
        const {data: settings} = await http.get('admin/settings');

        return settings;
      },

      async update (data) {
        const {data: settings} = await http.post('admin/settings', data);

        return settings;
      }
    },

    Cache: {
      async delete () {
        await http.delete('admin/cache/all');
      }
    },

    Files: {
      async getUpload (filename, type) {
        const {data: urls} = await http.post('admin/files/uploads', {filename, type});

        return urls;
      }
    },

    Users: {
      ...createCRUDMethods('admin/users'),

      async deleteProgress (id) {
        await http.delete(`admin/users/${id}/progress`);
      },

      async createNotification (id, data) {
        await http.post(`admin/users/${id}/notifications`, data);
      }
    },

    ModuleCategories: {
      ...createCRUDMethods('admin/module_categories')
    },

    Modules: {
      ...createCRUDMethods('admin/modules')
    },

    ModuleSteps: {
      ...createCRUDMethods('admin/module_steps')
    },

    ModuleComponents: {
      ...createCRUDMethods('admin/module_components')
    },

    GymCategories: {
      ...createCRUDMethods('admin/gym_categories')
    },

    GymItems: {
      ...createCRUDMethods('admin/gym_items')
    }
  };
};
