import React from 'react';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

const NoOptionsMessage = (props) => (
  <Typography
    color='textSecondary'
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const inputComponent = ({inputRef, ...props}) => (
  <div ref={inputRef} {...props} />
);

const Control = ({children, innerProps, innerRef, selectProps: {classes, TextFieldProps}}) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps
      }
    }}
    {...TextFieldProps}
  />
);

const Option = (props) => (
  <MenuItem
    ref={props.innerRef}
    selected={props.isFocused}
    component='div'
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = ({selectProps, innerProps = {}, children}) => (
  <Typography color='textSecondary' className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
);

const SingleValue = (props) => (
  <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Typography>
);

const ValueContainer = (props) => (
  <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
);

const MultiValue = (props) => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classnames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
    {...props.innerProps}
  />
);

const Menu = (props) => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
);

export default {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};
