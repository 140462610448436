import React, {useCallback} from 'react';
import isPlainObject from 'lodash/isPlainObject';
import isEqual from 'lodash/isEqual';
import cloneDeepWith from 'lodash/cloneDeepWith';
import {setLocale} from 'yup';
import {Formik} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

setLocale({
  mixed: {
    required: 'The field is required'
  },
  string: {
    email: 'The field must be a valid email'
  }
});

const omitUndefined = (obj) => {
  const omitFn = (value) => {
    if (isPlainObject(value)) {
      Object.keys(value).forEach((key) => {
        if (value[key] === undefined) {
          delete value[key];
        }
      });
    }
  };

  return cloneDeepWith(obj, omitFn);
};

const deepDiff = (o1, o2) => Object.keys(o2).reduce((diff, key) => {
  if (isPlainObject(o2[key])) {
    if (o1[key] === null || o1[key] === undefined) {
      return {
        ...diff,
        [key]: o2[key]
      };
    }

    const keyDiff = deepDiff(o1[key], o2[key]);

    if (Object.keys(keyDiff).length === 0) {
      return diff;
    }

    return {
      ...diff,
      [key]: keyDiff
    };
  }

  if (isEqual(o1[key], o2[key])) return diff;

  return {
    ...diff,
    [key]: o2[key]
  };
}, {});

const useStyles = makeStyles(() => ({
  formError: {
    whiteSpace: 'pre'
  }
}));

const Form = (
  {onSubmit, values: initialValues, output, submitLabel = 'Save', schema, patch, children}
) => {
  const styles = useStyles();
  const render = useCallback(({handleSubmit, status = {}, submitCount, isValid, isSubmitting}) => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {children}
        </Grid>
        {status.formError ? <Grid item xs={12}>
          <Typography color='error' data-testid='form-error' className={styles.formError}>
            {status.formError}
          </Typography>
        </Grid> : null}
        {(submitCount && !isValid) ? <Grid item xs={12}>
          <Typography color='error'>
            Some of the form fields are invalid, please check the errors
          </Typography>
        </Grid> : null}
        <Grid item xs={12}>
          <Button variant='contained' color='primary' type='submit' disabled={isSubmitting}>
            {submitLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  ), [children, styles.formError, submitLabel]);

  const handleSubmit = useCallback(async (values, {setStatus}) => {
    try {
      let formValues = output ? output(values) : values;
      formValues = omitUndefined(formValues);
      formValues = patch ? deepDiff(initialValues || {}, formValues) : formValues;
      await onSubmit(formValues);
    } catch (err) {
      if (err.message) {
        setStatus({
          formError: err.message
        });
      }
    }
  }, [initialValues, onSubmit, output, patch]);

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}
      validationSchema={schema} render={render}
    />
  );
};

export default Form;
