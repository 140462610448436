import React, {useCallback} from 'react';
import {useField} from 'formik';
import Button from '@material-ui/core/Button';

const FileField = ({name, label, target, inputProps = {}, ...props}) => {
  const [{value, onChange, ...field}] = useField({name, type: 'file'});

  const handleChange = useCallback((e) => {
    onChange({
      target: {
        name: e.target.name,
        value: {
          'url': URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
          type: e.target.files[0].type,
          target
        }
      }
    });
  }, [onChange, target]);

  return (
    <Button
      variant='contained'
      component='label'
      {...props}
    >
      {label}
      <input {...inputProps} {...field} type='file' style={{display: 'none'}}
        onChange={handleChange} />
    </Button>
  );
};

export default FileField;
