import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import RootStoreContext from './../contexts/RootStoreContext';

const useStyles = makeStyles(() => ({
  table: {
    width: 600,
    tableLayout: 'fixed'
  },
  name: {
    width: '50%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  progress: {
    width: '50%'
  }
}));

const Uploader = () => {
  const classes = useStyles();
  const {filesStore: {uploads}} = useContext(RootStoreContext);

  return (
    <Dialog open={uploads.length > 0}>
      <DialogTitle>Uploading...</DialogTitle>
      <Table className={classes.table}>
        <TableBody>
          {uploads.map((upload) => (
            <TableRow key={upload.id} hover>
              <TableCell className={classes.name}>{upload.name}</TableCell>
              <TableCell className={classes.progress}>
                <LinearProgress variant='determinate' value={upload.progress} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};

export default observer(Uploader);
