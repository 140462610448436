import React from 'react';
import capitalize from 'lodash/capitalize';
import * as Yup from 'yup';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import saboteurs from './../../../enum/saboteurs';
import Form from './../Form';
import TextField from './../fields/TextField';

const schema = Yup.object().shape({
});

const initialValues = {
};

const OnboardingSettingsForm = ({onSubmit, values}) => {
  return (
    <Form {...{onSubmit, schema}} values={values || initialValues} patch={!!values}>
      {Object.values(saboteurs).map((saboteur) => {
        const field = `saboteur_descriptions.${saboteur}`;

        return (
          <ExpansionPanel key={saboteur}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
              <Typography variant='h5'>{capitalize(saboteur.replace(/_/g, '-'))}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth name={`${field}.display_name`} label='Display Name' />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth
                    name={`${field}.short_description`} label='Short Description'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
                    name={`${field}.description`} label='Description'
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Form>
  );
};

export default OnboardingSettingsForm;
