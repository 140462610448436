import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useLocation} from './../../../hooks/router';

const GymCategoriesIndexPage = () => {
  const {gymCategoriesStore} = useContext(RootStoreContext);
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = gymCategoriesStore.getCollection();
    collection.load();
    return collection;
  }, [gymCategoriesStore]);

  const columns = useMemo(
    () => ([{
      title: 'Title',
      field: 'title'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<AddIcon />),
      tooltip: 'Add Gym Category',
      isFreeAction: true,
      onClick: () => location.navigate(`/gym_categories/new`)
    }, {
      icon: () => (<ListIcon />),
      tooltip: 'Edit Items',
      onClick: (e, gymCategory) => location.navigate(`/gym_categories/${gymCategory._id}/items`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit Gym Category',
      onClick: (e, gymCategory) => location.navigate(`/gym_categories/${gymCategory._id}`)
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete Gym Category',
      onClick: async (e, gymCategory) => {
        if (window.confirm(
          `Are you sure you want to delete Gym Category "${gymCategory.title}"?`
        )) {
          await gymCategoriesStore.delete(gymCategory._id);
          await collection.load();
        }
      }
    }]),
    [collection, gymCategoriesStore, location]
  );

  return (
    <Page title='Gym Categories'>
      <EntityList title='Gym Categories'
        columns={columns} actions={actions} collection={collection}
      />
    </Page>
  );
};

export default observer(GymCategoriesIndexPage);
