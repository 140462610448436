import React, {useCallback} from 'react';
import * as Yup from 'yup';
import capitalize from 'lodash/capitalize';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from './../fields/TextField';
import SwitchField from './../fields/SwitchField';
import ArraySubDocumentField from './../fields/ArraySubDocumentField';
import SelectField from './../fields/SelectField';
import HideableField from './../fields/HideableField';

export const schema = Yup.object().shape({
  pages: Yup.array().of(Yup.object().shape({
    body: Yup.object().shape({
      icon: Yup.string().nullable()
        .when('has_icon', {
          is: true,
          then: Yup.string().required()
        }),
      image: Yup.string().nullable()
        .when('has_image', {
          is: true,
          then: Yup.string().required()
        }),
      text: Yup.string().nullable()
        .when('has_text', {
          is: true,
          then: Yup.string().required()
        })
    }),
    footer: Yup.object().shape({
      text: Yup.string().nullable()
        .when('has_text', {
          is: true,
          then: Yup.string().required()
        })
    })
  }))
});

export const output = (value) => ({
  ...value,
  pages: (value.pages || []).map((page) => ({
    ...page,
    body: {
      ...page.body,
      icon: page.body.has_icon ? page.body.icon : null,
      image: page.body.has_image ? page.body.image : null,
      video: page.body.has_video ? page.body.video : null,
      text: page.body.has_text ? page.body.text : null,
      action: page.body.has_action ? page.body.action : null
    },
    footer: {
      ...page.footer,
      text: page.footer.has_text ? page.footer.text : null,
      action: page.footer.has_action ? page.footer.action : null
    }
  }))
});

const InstructionsFieldset = ({name}) => {
  const initNewItem = useCallback(() => ({
    body: {
      has_icon: false,
      icon: null,
      has_image: false,
      image: null,
      has_video: false,
      video: null,
      has_text: false,
      text: null,
      has_action: false,
      action: null
    },
    footer: {
      has_text: false,
      text: null,
      has_action: false,
      action: null
    }
  }), []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SwitchField name={`${name}.is_skippable`} label='Skippable' />
      </Grid>
      <Grid item xs={12}>
        <ArraySubDocumentField label='Page' title='Pages'
          name={`${name}.pages`} initNewItem={initNewItem}
          editForm={PageEditForm}
        />
      </Grid>
    </Grid>
  );
};

const PageEditForm = ({name}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>
            Body
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.body.has_icon`} label='With Icon' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.body.has_icon`}>
          <SelectField name={`${name}.body.icon`} label='Icon'
            options={['brain', 'info'].map((icon) => (
              {value: icon, label: capitalize(icon.replace(/_/g, ' '))}
            ))}
          />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.body.has_image`} label='With Image' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.body.has_image`}>
          <TextField fullWidth name={`${name}.body.image`} label='Image URL' />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.body.has_video`} label='With Video' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.body.has_video`}>
          <TextField fullWidth name={`${name}.body.video.vimeo_id`} label='Vimeo Id' />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.body.has_text`} label='With Text' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.body.has_text`}>
          <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
            name={`${name}.body.text`} label='Text'
          />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.body.has_action`} label='With Action' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.body.has_action`}>
          <ActionFieldset name={`${name}.body.action`} />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
            Footer
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.footer.has_text`} label='With Text' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.footer.has_text`}>
          <TextField fullWidth multiline rowsMax='10' rows='3' variant='outlined'
            name={`${name}.footer.text`} label='Text'
          />
        </HideableField>
      </Grid>
      <Grid item xs={12}>
        <SwitchField name={`${name}.footer.has_action`} label='With Action' />
      </Grid>
      <Grid item xs={12}>
        <HideableField ifName={`${name}.footer.has_action`}>
          <ActionFieldset name={`${name}.footer.action`} />
        </HideableField>
      </Grid>
    </Grid>
  );
};

const ActionFieldset = ({name}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <SelectField name={`${name}.type`} label='Action Type'
        options={['link', 'complete', 'set_reminders'].map((type) => (
          {value: type, label: capitalize(type.replace(/_/g, ' '))}
        ))}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.label`} label='Action Label' />
    </Grid>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.description`} label='Action Description' />
    </Grid>
    <Grid item xs={12}>
      <TextField fullWidth name={`${name}.href`} label='Action href' />
    </Grid>
  </Grid>
);

export default InstructionsFieldset;
