import {decorate, observable, action} from 'mobx';
import uniqueId from 'lodash/uniqueId';

export default (httpAgent) => {
  return decorate({
    uploads: [],

    async upload ({url, target, name, type}) {
      const id = uniqueId();

      this.uploads.push({id, name: name, progress: 0});
      const upload = this.uploads.find((u) => u.id === id);

      const urls = await httpAgent.Files.getUpload(target, type);

      const fileData = await readFile(url);

      const onprogress = (e) => {
        upload.progress = (e.loaded * 100) / e.total;
      };

      await uploadRequest(urls.upload, type, fileData, onprogress);

      this.uploads.remove(upload);

      return urls.download;
    }
  }, {
    uploads: observable,
    upload: action
  });
};

const readFile = async (url) => {
  return fetch(url).then(r => r.arrayBuffer());
};

const uploadRequest = async (url, type, data, onprogress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = onprogress;
    xhr.onload = xhr.onerror = () => {
      if (xhr.status === 200) {
        return resolve();
      }

      return reject(new Error('Upload Failed'));
    };

    xhr.open('PUT', url, true);

    xhr.setRequestHeader('Content-Type', type);

    xhr.send(data);
  });
};
