import React, {useContext, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import RootStoreContext from './../../../contexts/RootStoreContext';
import Page from './../../Page';
import EntityList from './../../EntityList';
import {useLocation} from './../../../hooks/router';

const ModulesIndexPage = () => {
  const {modulesStore} = useContext(RootStoreContext);
  const location = useLocation();

  const collection = useMemo(() => {
    const collection = modulesStore.getCollection();
    collection.load();
    return collection;
  }, [modulesStore]);

  const columns = useMemo(
    () => ([{
      title: 'Title',
      field: 'title'
    }, {
      title: 'Steps',
      field: 'steps_count'
    }]),
    []
  );

  const actions = useMemo(
    () => ([{
      icon: () => (<AddIcon />),
      tooltip: 'Add Module',
      isFreeAction: true,
      onClick: () => location.navigate(`/modules/new`)
    }, {
      icon: () => (<ListIcon />),
      tooltip: 'Edit Steps',
      onClick: (e, module) => location.navigate(`/modules/${module._id}/steps`)
    }, {
      icon: () => (<EditIcon />),
      tooltip: 'Edit Module',
      onClick: (e, module) => location.navigate(`/modules/${module._id}`)
    }, {
      icon: () => (<DeleteIcon />),
      tooltip: 'Delete Module Category',
      onClick: async (e, module) => {
        if (window.confirm(
          `Are you sure you want to delete module "${module.title}"?`
        )) {
          await modulesStore.delete(module._id);
          await collection.load();
        }
      }
    }]),
    [collection, location, modulesStore]
  );

  return (
    <Page title='Modules'>
      <EntityList title='Modules'
        columns={columns} actions={actions} collection={collection}
      />
    </Page>
  );
};

export default observer(ModulesIndexPage);
