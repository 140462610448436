import React, {useContext, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import RootStoreContext from './../contexts/RootStoreContext';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
  const {authStore} = useContext(RootStoreContext);

  const renderComponent = useCallback(
    (props) => {
      if (authStore.authenticated) {
        return <Component {...props} />;
      }

      return (
        <Redirect to={{
          pathname: '/login',
          query: {next: props.location}
        }} />
      );
    },
    [authStore.authenticated]
  );

  return (
    <Route {...rest} render={renderComponent} />
  );
};

export default observer(PrivateRoute);
