import React from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#7c8ca8',
      main: '#4f5f79',
      dark: '#24354d',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffffc1',
      main: '#ffce90',
      dark: '#ca9d62',
      contrastText: '#ffffff'
    }
  }
});

const withRoot = (Component) => {
  return (props) => (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Component {...props} />
    </MuiThemeProvider>
  );
};

export default withRoot;
