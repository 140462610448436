import {createCRUDStore, uploadAudio} from './utils';

export default (httpAgent, commonStore, filesStore) => {
  const uploadFiles = async (data) => {
    let result = {...data};

    if (data.shortcut && data.shortcut.data && data.shortcut.data.file) {
      result.shortcut = {
        ...data.shortcut,
        data: await uploadAudio(data.shortcut.data, filesStore)
      };
    }

    if (data.data_sets) {
      result.data_sets = await Promise.all(data.data_sets.map(async (dataSet) => {
        let uploadedDataSet = {...dataSet};

        const {data} = dataSet;

        if (data && data.audio && data.audio.file) {
          uploadedDataSet.data = {
            ...data,
            audio: await uploadAudio(data.audio, filesStore)
          };
        }

        if (data && data.saboteurs_audio) {
          uploadedDataSet.data = {
            ...data,
            saboteurs_audio: {
              ...data.saboteurs_audio,
              saboteurs: {}
            }
          };

          for (const saboteur of Object.keys(data.saboteurs_audio.saboteurs)) {
            uploadedDataSet.data.saboteurs_audio.saboteurs[saboteur] = {
              ...data.saboteurs_audio.saboteurs[saboteur],
              audio:
              data.saboteurs_audio.saboteurs[saboteur].audio.file
                ? await uploadAudio(data.saboteurs_audio.saboteurs[saboteur].audio, filesStore)
                : data.saboteurs_audio.saboteurs[saboteur].audio
            };
          }
        }

        return uploadedDataSet;
      }));
    }

    return result;
  };

  return createCRUDStore(
    httpAgent.ModuleComponents, commonStore, 'Module Component',
    {
      beforeCreate: uploadFiles,
      beforeUpdate: uploadFiles
    }
  );
};
