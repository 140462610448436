import React, {useContext, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Redirect} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RootStoreContext from './../../contexts/RootStoreContext';
import LoadingOverlay from './../LoadingOverlay';
import LoginForm from './../forms/LoginForm';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%'
  }
}));

const LoginPage = () => {
  const classes = useStyles();
  const {authStore} = useContext(RootStoreContext);

  const handleSubmit = useCallback(async ({login, password}) => {
    await authStore.login(login, password);
  }, [authStore]);

  const {isLoading, authenticated} = authStore;

  if (authenticated) {
    return <Redirect to={{pathname: '/'}} />;
  }

  return (
    <Grid container justify='center' alignItems='center' className={classes.container}>
      <Grid item xs={12} sm={7} lg={4}>
        <Card>
          <CardContent>
            {isLoading ? <LoadingOverlay size={42} /> : null}
            <LoginForm onSubmit={handleSubmit} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default observer(LoginPage);
