import React, {useCallback} from 'react';
import {useField, FieldArray} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    flexGrow: 1,
    position: 'relative',
    padding: theme.spacing(2),
    // toolbar
    marginTop: theme.spacing(8)
  }
}));

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ArraySubDocumentField = ({name, ...props}) => {
  const [{value}] = useField({name});

  return (
    <FieldArray name={name} render={(arrayProps) => (
      <SubDocuments {...{name, value}} {...props} {...arrayProps} />
    )} />
  );
};

const SubDocuments = ({
  name, value, title, label, editForm, getTitle, initNewItem, push, remove
}) => {
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const newItemIndex = value ? value.length : 0;

  const handleEditItem = useCallback((index) => setItemToEdit(index), []);
  const handleAddItem = useCallback(() => {
    push(initNewItem ? initNewItem() : {});
    // edit newly added item
    setItemToEdit(newItemIndex);
  }, [initNewItem, newItemIndex, push]);
  const handleClose = useCallback(() => setItemToEdit(null), []);

  return (
    <>
      <EditDialog {...{itemToEdit, handleClose, label, editForm, name}} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs>
              <Typography variant='h6'>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={`Add ${label}`}>
                <span>
                  <IconButton color='inherit' onClick={handleAddItem}>
                    <AddIcon size='small' />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
              {(value || []).map((item, index) => (
                <SubDocument key={index} {...{index, getTitle, label}} name={`${name}.${index}`}
                  onEdit={handleEditItem} onRemove={remove}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
};

const SubDocument = ({name, index, getTitle, label, onEdit, onRemove}) => {
  const [{value}, meta] = useField({name});
  const handleEdit = useCallback(() => onEdit(index), [index, onEdit]);
  const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <TableRow>
      <TableCell>
        <Typography color={meta.error ? 'error' : undefined}>
          {getTitle ? getTitle(value, index) : `${label} #${index + 1}`}
          {meta.error ? ' (has validation errors)' : null}
        </Typography>
      </TableCell>
      <TableCell size='small' padding='none' width={48 * 2}>
        <Tooltip title={`Edit ${label}`}>
          <span>
            <IconButton color='inherit'
              onClick={handleEdit}
            >
              <EditIcon size='small' />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={`Delete ${label}`}>
          <span>
            <IconButton color='inherit'
              onClick={handleRemove}
            >
              <DeleteIcon size='small' />
            </IconButton>
          </span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const EditDialog = ({itemToEdit, handleClose, label, editForm: EditForm, name}) => {
  const classes = useStyles();

  if (itemToEdit === null) {
    return null;
  }

  return (
    <Dialog fullScreen open disableBackdropClick
      TransitionComponent={Transition} onClose={handleClose}
    >
      <AppBar>
        <Toolbar>
          <Grid container spacing={0}>
            <Grid item xs>
              <Typography variant='h6'>
                  Edit {label} #{itemToEdit + 1}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color='inherit' size='small' onClick={handleClose}>
                <CloseIcon size='small' />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EditForm name={`${name}.${itemToEdit}`} />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' color='primary' onClick={handleClose}>
                Done
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ArraySubDocumentField;
