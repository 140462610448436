import {useContext, useMemo} from 'react';
import {__RouterContext as RouterContext} from 'react-router';

export function useRouter () {
  return useContext(RouterContext);
};

export function useParams () {
  const {match} = useRouter();
  return match.params;
}

export function useLocation () {
  const {location, history} = useRouter();

  return useMemo(() => {
    const navigate = (to, {replace = false} = {}) => {
      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }
    };

    return {
      location,
      navigate
    };
  }, [history, location]);
}
