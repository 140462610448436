module.exports = {
  AVOIDER: 'avoider',
  CONTROLLER: 'controller',
  HYPER_ACHIEVER: 'hyper_achiever',
  HYPER_RATIONAL: 'hyper_rational',
  HYPER_VIGILANT: 'hyper_vigilant',
  PLEASER: 'pleaser',
  RESTLESS: 'restless',
  STICKLER: 'stickler',
  VICTIM: 'victim',
  JUDGE: 'judge'
};
