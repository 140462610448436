import React from 'react';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AuthBar from './AuthBar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  }
}));

const TopBar = ({title, onDrawerOpen, isDrawerOpen}) => {
  const classes = useStyles();

  return (
    <AppBar
      position='fixed'
      className={classnames(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen
      })}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='Open drawer'
          onClick={onDrawerOpen}
          edge='start'
          className={classnames(classes.menuButton, {
            [classes.hide]: isDrawerOpen
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' noWrap className={classes.title}>
          {title}
        </Typography>
        <div>
          <AuthBar />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
