import React from 'react';
import Typography from '@material-ui/core/Typography';
import Page from './../Page';

const MainPage = () => {
  return (
    <Page title='Dashboard'>
      <Typography paragraph>
          Welcome!
      </Typography>
    </Page>
  );
};

export default MainPage;
