import createCommonStore from './../stores/CommonStore';
import createAuthStore from './../stores/AuthStore';
import createSettingsStore from './../stores/SettingsStore';
import createFilesStore from './../stores/FilesStore';
import createUsersStore from './../stores/UsersStore';
import createModuleCategoriesStore from './../stores/ModuleCategoriesStore';
import createModulesStore from './../stores/ModulesStore';
import createModuleStepsStore from './../stores/ModuleStepsStore';
import createModuleComponentsStore from './../stores/ModuleComponentsStore';
import createGymCategoryStore from './../stores/GymCategoriesStore';
import createGymItemsStore from './../stores/GymItemsStore';

export default (httpAgent, store) => {
  const commonStore = createCommonStore(store);
  const authStore = createAuthStore(httpAgent, store);
  const settingsStore = createSettingsStore(httpAgent, commonStore);
  const filesStore = createFilesStore(httpAgent);
  const usersStore = createUsersStore(httpAgent, commonStore);
  const moduleCategoriesStore = createModuleCategoriesStore(httpAgent, commonStore);
  const modulesStore = createModulesStore(httpAgent, commonStore, filesStore);
  const moduleStepsStore = createModuleStepsStore(httpAgent, commonStore);
  const moduleComponentsStore = createModuleComponentsStore(httpAgent, commonStore, filesStore);
  const gymCategoriesStore = createGymCategoryStore(httpAgent, commonStore);
  const gymItemsStore = createGymItemsStore(httpAgent, commonStore, filesStore);

  return {
    commonStore,
    authStore,
    settingsStore,
    filesStore,
    usersStore,
    moduleCategoriesStore,
    modulesStore,
    moduleStepsStore,
    moduleComponentsStore,
    gymCategoriesStore,
    gymItemsStore
  };
};
